import React, { useRef } from "react";
import Alert from "../assets/audio/mixkit-software-interface-back-2575.wav";
import { toast } from "react-toastify";

const SoundAlert = ({ alert, isUserInteracted }) => {
  const audioRef = useRef(null);

  // Function to play the sound
  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
      toast.success("There is a message from a client", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };


  // Example: Automatically play sound when the alert prop changes
  React.useEffect(() => {
    if (alert && isUserInteracted) {
      playSound();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return (
    <div>
      <audio ref={audioRef}>
        <source src={Alert} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default SoundAlert;
